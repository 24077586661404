import { Flex, ButtonGroup, Button, TabPanel, Image } from "@chakra-ui/react"
import { checkClientPermission } from "../../../Users/utils/checkPermission";
import { IResponse } from "../../../../../_services/interface/user.interface";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { ReloadIcon } from "../../Icons/Reload";
import { useEffect, useState } from "react";
import { CompanyObligationsSelector } from "../../modals/CompanyObligationSelector";
import { FilterDataSelected, FilterRow, Filters, FilterTypeEnum } from "../../../../../components/Filters";
import { Datatables } from "../../../../../components/Table/Datatables";
import { IAction, IColumn, IData, ITableMetaData } from "../../../../../components/Table/table.interface";
import { IFilterListSchedule, IScheduleForList } from "../../interfaces/Schedule.interface";
import { listSchedule } from "../../../../../_services/irs.service";
import { EditScheduleModal } from "../../modals/EditScheduleModal";
import { AutomationCreateModal } from "../../modals/Automation/AutomationCreateModal";
import { EditCardScheduleModal } from '../../modals/EditCardScheduleModal'
interface ISchedule {
  guid_client: string;
  refreshHook: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: boolean;
}

export const ScheduleTabPanel = (props: ISchedule) => {  
  const { validate } = useLoginFormStore();
  const [isLoading, setLoadingState] = useState<boolean>(true);

  const [scheduleData, setScheduleData] = useState<IData<IScheduleForList>|null>(null);

  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [currentSchedule, setCurrentSchedule] = useState<IScheduleForList>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openEditCardModal, setOpenEditCardModal] = useState<boolean>(false);

  useEffect(() => {
    if (props.refreshData) {
      props.refreshHook(false);
      setRefreshData(true);
    }
  }, [props.refreshData]);

  useEffect(() => {
    setRefreshData(true);
  }, [
    metaData,
    props.guid_client,
    filterDataSelected,
  ]);

  useEffect(() => {
    if(refreshData) {
      fetchListScheduleData();
      setRefreshData(false);
    }
  }, [
    refreshData
  ]);

  const fetchListScheduleData = async () => {
    if (props.guid_client) {
      setLoadingState(true);    
      const filters = getFilter();
      const { status, response } = await listSchedule(props.guid_client, filters);
      if (status === 200 && 'meta' in response && 'data' in response) {
        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          setScheduleData(response);
        }
      }
      setTimeout(() => {
        setLoadingState(false);
      }, 1000);
    }
  }

  
  const getFilter = (): IFilterListSchedule => {

    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const jurisdiction: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const companies: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [];
    const schedules: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.kanbanScheduleFilter] : [];
    const collaborators: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.collaboratorFilter] : [];
    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const status: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.kanbanScheduleStatusFilter] : [];

    const filters: IFilterListSchedule = {
      page: metaData?.currentPage ?? 1,
      row_by_page: metaData?.rowByPage ?? -1,
      order_field: metaData?.orderField ?? 'createdAt',
      order_direction: metaData?.orderDirection ?? 'desc',
      ...(validFilterField(jurisdiction) ? { jurisdiction: jurisdiction.join(',') } : {}),
      ...(validFilterField(schedules) ? { guids: schedules.join(',') } : {}),
      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(collaborators) ? { collaborators: collaborators.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { groupCompanies: companyGroups.join(',') } : {}),
      ...(validFilterField(status) ? { isActive: status.join(',') } : {}),
    };

    return filters;
  }

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.kanbanScheduleFilter,
          position: 1,
          size: 4,
        },
        {
          name: FilterTypeEnum.companyFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 3,
          size: 4,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.jurisdictionFilter,
          position: 1,
          size: 4,
        },
        {
          name: FilterTypeEnum.kanbanScheduleStatusFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.collaboratorFilter,
          position: 3,
          size: 4,
        },
      ],
      position: 2
    },
  ] as FilterRow[];

  const columns = [
    {
      title: 'Nome do agendamento',
      name: 'rulename',
      sortable: true,
      align: 'left',
    },
    {
      title: 'Data de criação',
      name: 'createdAtFormatted',
      orderFieldName: 'createdAt',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Obrigações / Cards',
      name: 'obligation_card_count',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Empresas',
      name: 'companies_count',
      sortable: false,
      align: 'center',
    },
    
  ] as IColumn[];

  
  const actions = [
    {
      text: 'Editar',
      icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
      isVisible: (schedule: IScheduleForList): boolean => {return true},
      isDisabled: (schedule: IScheduleForList): boolean => { return false },
      action: (schedule: IScheduleForList) => {
        setCurrentSchedule(schedule);

        if (schedule.type === "CARD_STRUCTURE") {
          setOpenEditCardModal(true);
        } else {
          setOpenEditModal(true);
        }
      },
    },
  ] as IAction[];

  return (
    <>
      <TabPanel>
          <Flex flexDirection="column" gap={4}>
            <Flex flexDirection="row" justifyContent="flex-end">
              {checkClientPermission(validate as IResponse, props.guid_client, 'kanban.write') ? (
                <Flex>
                  <ButtonGroup size="sm" mt="24px" color="white">
                    <Button leftIcon={<ReloadIcon />} bgColor="#4B4EFF" color="white" onClick={() => setRefreshData(true)} _hover={{ bg: '#282be0' }}>
                      Atualizar
                    </Button>
                    <AutomationCreateModal
                      guid_client={props.guid_client}
                      flushHook={setRefreshData}
                    />
                  </ButtonGroup>
                </Flex> 
              ) : null}
            </Flex>
            
            <Flex display={"flex"} direction={"column"}>
              <Filters 
                guid_client={props.guid_client}
                filters={filterRows}
                selectedValuesHook={setFilterDataSelected}
                reinitFilter={refreshData}
              />
            </Flex>
            
            <Datatables<IScheduleForList>
              name="Agendamentos"
              columns={columns}
              actions={actions}
              metaDataHook={setMetaData}
              data={scheduleData}
              isLoading={isLoading}
            />

          </Flex>
      </TabPanel>

      {currentSchedule && (
        <>
          <EditScheduleModal
            icon={true}
            flushHook={setRefreshData}
            ruleName={currentSchedule.rulename}
            guid_client={props.guid_client}
            guid_schedule={currentSchedule.guid_schedule}
            openModal={openEditModal}
            openModalHook={setOpenEditModal}
          />

          <EditCardScheduleModal
            icon={true}
            flushHook={setRefreshData}
            ruleName={currentSchedule.rulename}
            guid_client={props.guid_client}
            guid_schedule={currentSchedule.guid_schedule}
            openModal={openEditCardModal}
            openModalHook={setOpenEditCardModal}
          />
        </>
      )}
    </>
  );
}

