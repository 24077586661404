import { Button, ButtonGroup, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilterDataSelected, FilterRow, Filters, FilterTypeEnum } from "../../../../components/Filters";
import { Datatables } from "../../../../components/Table/Datatables";
import { IAction, IColumn, IData, ITableMetaData } from "../../../../components/Table/table.interface";
import { stateRegionForTag } from "../../../../components/Table/utils";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { getBulkUpdateCompanyItens, getBulkUpdateCompanyPending, listCompany, listCompanyWithoutPermission, toggleCompany } from "../../../../_services/company.service";
import { IClientCompanies, IBulkUpdateCompany, IBulkUpdateCompanyItem, IFilterListCompany, ICompany } from "../../../../_services/interface/company.interface";
import { IResponse } from "../../../../_services/interface/user.interface";
import { checkClientPermission } from "../../Users/utils/checkPermission";
import { CreateCompanyModal } from "./modals/CreateCompanyModal";
import { EditCompanyModal } from "./modals/EditCompanyModal";
import { CreateModal } from "./modals/CreateBulkCompany/CreateModal";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { getCancelToken } from "../../../../_services/cancelToken";
import AlertToast from "../../../../components/AlertToast/AlertToast";
import { Alert } from "../../../../components/Alert";
import { ExportCompanies } from "./modals/ExportCompanies";
import debounce from 'lodash/debounce';
import { CreateForeignCompanyModal } from "./modals/CreateForeignCompanyModal";
import { EditForeignCompanyModal } from "./modals/EditForeignCompanyModal";

interface ICompanyPanelProps {
  guid_client: string;
  refreshDataHook: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: boolean;
  totalCompaniesHook: React.Dispatch<React.SetStateAction<number>>;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
}

export const CompanyPanel = (props: ICompanyPanelProps) => {
  const { validate } = useLoginFormStore();
  const toast = useToast();
  const toastIdRef = useRef<any>();

  const [currentCompany, setCurrentCompany] = useState<ICompany>();
  const [openEditCompanyModal, setOpenEditCompanyModal] = useState<boolean>(false);
  const [openEditForeignCompanyModal, setOpenEditForeignCompanyModal] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();
  const [companiesList, setCompaniesList] = useState<IData<any>|null>(null);
  const [isExternal, setExternal] = useState<boolean>(false);

  const [companiesSubTableList, setCompaniesSubTableList] = useState<IData<any>|null>(null);
  const [companySubTableMetaData, setCompanySubTableMetaData] = useState<ITableMetaData|undefined>();
  const [isLoadingSubTable, setLoadingStateSubTable] = useState<boolean>(false);

  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const [bulkUpdate, setBulkUpdate] = useState<IBulkUpdateCompany | undefined>();
  const [bulkUpdateItens, setBulkUpdateItens] = useState<IBulkUpdateCompanyItem[]>([]);
  const [showInfoToast, setShowInfoToast] = useState<boolean>(false);
  const [refreshConfirmationModal, setRefreshConfirmationModal] = useState<boolean>(false);
  const [openBulkCompanyModal, setOpenBulkCompanyModal] = useState<boolean>(false);

  const getCompanySubTableData = async (row: IClientCompanies) => {
    if (props.guid_client) {
      setLoadingStateSubTable(true);
  
      const filters: IFilterListCompany = {
        page: companySubTableMetaData?.currentPage ?? 1,
        row_by_page: companySubTableMetaData?.rowByPage ?? -1,
        order_field: companySubTableMetaData?.orderField ?? 'createdAt',
        order_direction: companySubTableMetaData?.orderDirection ?? 'desc',
        cnpj_base: row.cnpj?.substring(0, 8),
        type: '1'
      };

      const { status, response } = await listCompanyWithoutPermission(props.guid_client, filters);
      if (status === 200) {
        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          
          setCompaniesSubTableList(response);
        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });

      }
      setTimeout(() => {
        setLoadingStateSubTable(false);
      }, 1000);
    }
  }

  const getCompanyData = async () => {
    if (props.guid_client) {
      setLoading(true);
  
      const filters = getFilter();
      const { status, response } = await listCompany(props.guid_client, filters);
      if (status === 200 && 'meta' in response && 'data' in response) {
        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          if (response.meta.totalCountWithoutGroup !== undefined) {
            props.totalCompaniesHook(response.meta.totalCountWithoutGroup);
          }
          
          setCompaniesList(response);
          setExternal(response.isExternal);
        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });

      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  const getFilter = (): IFilterListCompany => {
    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const status: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyStatusFilter] : [];
    const type: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [];
    const company: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [];
    const companyGroup: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const code: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];
    const state: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyStateFilter] : [];
    const city: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCityFilter] : [];
    const situation: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companySituationFilter] : [];
    const situationIe: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companySituationIeFilter] : [];

    const filters: IFilterListCompany = {
      page: companyMetaData?.currentPage ?? 1,
      row_by_page: companyMetaData?.rowByPage ?? 10,
      order_field: companyMetaData?.orderField ?? 'createdAt',
      order_direction: companyMetaData?.orderDirection ?? 'desc',
      groupMatriz: true,
      ...(validFilterField(status) ? { status: status.join(',') } : {}),
      ...(validFilterField(type) ? { type: type.join(',') } : {}),
      ...(validFilterField(company) ? { company: company.join(',') } : {}),
      ...(validFilterField(companyGroup) ? { company_group: companyGroup.join(',') } : {}),
      ...(validFilterField(code) ? { code: code.join(',') } : {}),
      ...(validFilterField(state) ? { state: state.join(',') } : {}),
      ...(validFilterField(city) ? { city: city.join(',') } : {}),
      ...(validFilterField(situation) ? { situation: situation.join(',') } : {}),
      ...(validFilterField(situationIe) ? { situationIe: situationIe.join(',') } : {}),
    };

    return filters;
  }

  const handleToggleCompany = async (company: ICompany) => {
    toastIdRef.current = toast({
      title: `${company.isActive ? 'Desativar' : 'Ativando'} empresa`,
      description: `Estamos ${company.isActive ? 'desativando' : 'ativando'} a empresa ${company.name}.`,
      status: 'loading',
      isClosable: false,
      duration: null,
    });
    const { status } = await toggleCompany(company.guid_company)
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
    if (status === 200) {
      toast({
        title: `Empresa ${company.isActive ? 'desativada' : 'ativada'}`,
        description: `A empresa ${company.name} foi ${company.isActive ? 'desativada' : 'ativada'}.`,
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
    } else {
      toast({
        title: `Ocorreu um erro ao ${company.isActive ? 'desativar' : 'ativar'} a empresa.`,
        description: `A empresa ${company.name} não foi ${company.isActive ? 'desativar' : 'ativada'}.`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
    }
    props.refreshDataHook(true)
  }

  const fetchBulckUpdateCompany = async () => {
    setBulkUpdate(undefined);
    const bulkUpdate = await getBulkUpdateCompanyPending(props.guid_client);
    if(bulkUpdate && 'guid_bulk_update' in bulkUpdate) {
      setBulkUpdate(bulkUpdate as unknown as IBulkUpdateCompany);
    }
  }

  const fetchItems = async () => {
    setBulkUpdateItens([])
    if(bulkUpdate && bulkUpdate.guid_bulk_update) {
      const items = await getBulkUpdateCompanyItens(bulkUpdate.guid_bulk_update, getCancelToken('company.bulkCompanyGetItems'));
      setBulkUpdateItens(items as unknown as IBulkUpdateCompanyItem[]);
    }
  }

  const columns = [
    {
      title: 'Empresa - cód',
      name: 'nameWithCode',
      orderFieldName: 'name',
      sortable: true,
      align: 'left',
      subTable: (row: ICompany) => {return row.has_filial},
      elipse: {
        columnName: 'isScheduled',
        tooltipTextTrue: 'Agendada a pesquisa de certidões',
        tooltipTextFalse: 'Não agendada a pesquisa de certidões',
        validation: (isScheduled: boolean) => {
          return isScheduled;
        },
        canShow: (isForeign: boolean) => {
          return !isForeign;
        }
      }
    },
    {
      title: 'Tipo',
      name: 'type',
      sortable: true,
      align: 'center',
      tags: [
        { 
          value: 0, 
          text: 'Matriz',
          color: '#ECFCCB',
          textColor: '#365314'
        },
        { 
          value: 1,
          text: 'Filial',
          color: '#C4F1F9',
          textColor: '#065666'
        },
        { 
          value: 2,
          text: 'Estrangeira',
          color: '#CCFBF1',
          textColor: '#134E4A',
        }
      ]
    },
    {
      title: 'CNPJ',
      name: 'cnpj_formatted',
      orderFieldName: 'cnpj',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Situação',
      name: 'situation',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Situação Inscrição Estadual',
      name: 'situationIe',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Grupo',
      name: 'grupoLabel',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Inscrição Estadual',
      name: 'stateReg',
      sortable: true,
      align: 'center',
    },
    ...(isExternal ? [
      {
      title: 'Inscrição Municipal',
      name: 'municipalReg',
      sortable: true,
      align: 'center',
    }] : []),
    {
      title: 'Estado',
      name: 'initials',
      orderFieldName: 'city.state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      orderFieldName: 'city.name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Status',
      name: 'isActive',
      sortable: true,
      align: 'center',
      tags: [
        { 
          value: true, 
          text: 'Ativa',
          color: '#DCFCE7',
          textColor: '#171923'
        },
        { 
          value: false,
          text: 'Inativa',
          color: '#FEE2E2',
          textColor: '#7F1D1D'
        }
      ]
    },
  ] as IColumn[];

  const columnsSubTable = columns.map((column, index) => {
    if (index === 0) {
      const { subTable, ...rest } = column;
      return rest;
    }
    return column;
  });

  const showUpdatedPanel = () => {
    if(openBulkCompanyModal) {
      toast.closeAll();
      return (<></>)
    }
    toast.closeAll();

    const itemsFinished = bulkUpdateItens.filter((i) => i.status !== "PENDING" && i.status !== "PENDING_IE").length;
    const totalItems = bulkUpdateItens.length;

    let className = "alert-instance";
    let status: "warning" | "success" | "error" | "info" = "info";
    let descriptionHtml = `<strong>${itemsFinished} de ${totalItems}</strong> empresas já foram buscadas.`;
    let clicable = { action: () => setOpenBulkCompanyModal(true), text: "Ver Detalhes" };
    let type: "two" | "three" | "one" = "three";
    let variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent" = "alert";
    let justify: "start" = "start";

    if (itemsFinished === totalItems) {
      status = "success";
      descriptionHtml = `<strong>${itemsFinished} de ${totalItems}</strong> empresas buscadas.`;
    }

    return (
      <>
        <Alert
          isClosable={false}
          className={className}
          status={status}
          descriptionHtml={descriptionHtml}
          clicable={clicable}
          type={type}
          variant={variant}
          justify={justify}
        />
      </>
    );
  };
  
  const showUpdatedToast = () => {
    if(openBulkCompanyModal) {
      toast.closeAll();
      return (<></>)
    }
    const hideToastAndShowAlertOnTop = () => {
      setShowInfoToast(false);
    };

    const itemsFinished = bulkUpdateItens.filter((i) => i.status !== "PENDING" && i.status !== "PENDING_IE").length;
    const totalItems = bulkUpdateItens.length;

    let closableFunc = hideToastAndShowAlertOnTop;
    let className = "alert-instance";
    let status: "warning" | "success" | "error" | "info" = "info";
    let descriptionHtml = `Atualizado <strong>${itemsFinished} de ${totalItems}</strong> empresas.`;
    let clicable = { action: () => setOpenBulkCompanyModal(true), text: "Ver Detalhes" };
    let type: "two" | "three" | "one" = "three";
    let variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent" = "alert";

    if (itemsFinished === totalItems) {
      status = "success";
      descriptionHtml = `<strong>${itemsFinished} de ${totalItems}</strong> empresas atualizadas.`;
    }

    return (
      <AlertToast
        closableFunc={closableFunc}
        className={className}
        status={status}
        descriptionHtml={descriptionHtml}
        clicable={clicable}
        type={type}
        variant={variant}
      />
    );
  };

  const actions = [
    {
      text: 'Editar',
      icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
      isVisible: (company: ICompany): boolean => {return hasProductForeignCompany() || !company.isForeign},
      isDisabled: (company: ICompany): boolean => {return !checkClientPermission(validate as IResponse, props.guid_client, 'company.write')},
      action: (company: ICompany) => {
          setCurrentCompany(company);
          company.isForeign ? setOpenEditForeignCompanyModal(true) : setOpenEditCompanyModal(true);
        }
      ,
    },
    {
      text: (company: ICompany): string => { return company.isActive ? 'Desativar' : 'Ativar'},
      icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
      isVisible: (company: ICompany): boolean => {return true},
      isDisabled: (company: ICompany): boolean => { return !checkClientPermission(validate as IResponse, props.guid_client, 'company.delete') },
      action: (company: ICompany) => handleToggleCompany(company),
    }
  ] as IAction[];

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyTypeFilter,
          position: 3,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 4,
          size: 2,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyStatusFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.companySituationFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companySituationIeFilter,
          position: 3,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyStateFilter,
          position: 4,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyCityFilter,
          position: 5,
          size: 2,
        }
      ],
      position: 2
    }
  ] as FilterRow[];


  useEffect(() => {
    if(openBulkCompanyModal) {
      toast.closeAll();
    }
  }, [openBulkCompanyModal]);

  useEffect(() => {
    if (props.refreshData) {
      getCompanyData();
      props.refreshDataHook(false);
    }
  }, [props.refreshData])

  const debouncedRefreshData = useCallback(debounce(() => props.refreshDataHook(true), 400), []);
  useEffect(() => {
    if(filterDataSelected !== null && props.guid_client) {
      debouncedRefreshData();
    }
  }, [companyMetaData, filterDataSelected, props.guid_client]);

  useEffect(() => {
    fetchBulckUpdateCompany();
  }, []);

  useEffect(() => {
    if (refreshConfirmationModal) {
      fetchBulckUpdateCompany();
      setRefreshConfirmationModal(false);
    }
  }, [refreshConfirmationModal]);

  useEffect(() => {
    if(bulkUpdate) {
      fetchItems();
    }
  }, [bulkUpdate]);

  useEffect(() => {
    if (props.socket) {
      props.socket.off("refreshBulkCompanyList");
      activeWebsocket(props.socket);
    }
    // eslint-disable-next-line
  }, [props.socket, bulkUpdate]);

  const activeWebsocket = (
    socket: Socket<DefaultEventsMap, DefaultEventsMap>
  ) => {
    socket.on("refreshBulkCompanyList", (message) => {
      setRefreshConfirmationModal(true);
    });
  };

  const hasProductForeignCompany = () => {
    const client = (validate as IResponse).clients.find((cl) => cl.guid_client === props.guid_client);
    if (client) {
      return client.products['foreignCompany'];
    }

    return false;
  }

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" mt="10px">
      <Flex flexDirection="row" justifyContent="flex-end" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
        {!isLoading && checkClientPermission(validate as IResponse, props.guid_client, 'company.write') ? (
          <Flex 
            flexDirection="column"
            w="100%"
            gap={4}
          >
            <Flex justifyContent='flex-end' gap={4}>
              <ExportCompanies companiesCount={companiesList?.meta.totalCount} guid_client={props.guid_client} />
              <CreateModal 
                flushHook={() => {props.refreshDataHook(true); fetchBulckUpdateCompany();}}
                guid_client={props.guid_client}
                socket={props.socket}
                bulkUpdate={bulkUpdate}
                bulkUpdateItens={bulkUpdateItens}
                isOpen={openBulkCompanyModal}
                setIsOpen={setOpenBulkCompanyModal}
                showInfoToast={showInfoToast}
                setShowInfoToast={setShowInfoToast}
                refreshBulkUpdate={() => fetchBulckUpdateCompany()}
              />
              <CreateCompanyModal flushHook={props.refreshDataHook} guid_client={props.guid_client} />
              { hasProductForeignCompany() ? (<CreateForeignCompanyModal flushHook={props.refreshDataHook} guid_client={props.guid_client} />) : null }
            </Flex>

            <Flex w="100%">
              {bulkUpdate && bulkUpdateItens && bulkUpdateItens.length > 0 && !showInfoToast && !openBulkCompanyModal ? showUpdatedPanel() : null}
              {bulkUpdate && bulkUpdateItens && bulkUpdateItens.length > 0 && showInfoToast && !openBulkCompanyModal ? showUpdatedToast() : null}
            </Flex>
          </Flex>
        ) : null}
      </Flex>

      <Flex mt={4}>
        <Filters
          guid_client={props.guid_client}
          filters={filterRows}
          selectedValuesHook={setFilterDataSelected}
          allCompanies={true}
        />
      </Flex>

      <Flex mt={12} direction="column">
        <Datatables
          name="Empresas"
          columns={columns}
          actions={actions}
          metaDataHook={setCompanyMetaData}
          data={companiesList}
          isLoading={isLoading}
          refreshSubTableHook={getCompanySubTableData}
        >
          <Datatables
            name="Empresas"
            columns={columnsSubTable}
            actions={actions}
            data={companiesSubTableList}
            isLoading={isLoadingSubTable}
            metaDataHook={setCompanySubTableMetaData}
            pagination={false}
            rowByPage={false}
          />
        </Datatables>
      </Flex>
    </Flex>

    <EditCompanyModal 
      guid_client={props.guid_client}
      company={currentCompany}
      flushHook={props.refreshDataHook}
      openModal={openEditCompanyModal}
      openModalHook={setOpenEditCompanyModal}
    />

    <EditForeignCompanyModal 
      guid_client={props.guid_client}
      company={currentCompany as unknown as IClientCompanies}
      flushHook={props.refreshDataHook}
      openModal={openEditForeignCompanyModal}
      openModalHook={setOpenEditForeignCompanyModal}
    />
  </>)
};