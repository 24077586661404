import { Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Select, useToast, Tooltip, Text, Checkbox, Grid, GridItem, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyState, IClientCompanies, ICompany } from "../../../../../_services/interface/company.interface";
import { IGetCompany } from "../../../../../_services/interface/company.interface";
import { useEffect, useState } from "react";
import { getCityByState, getStates } from "../../../../../_services/region.service";
import { ICityResponse, IRegionResponse } from "../../../../../_services/interface/region.interface";
import { FormButton } from "../../../../../components/login/button.component";
import { getCompany, postEditCompany } from "../../../../../_services/company.service";
import { validate as validateCNPJ } from "cnpj";
import { checkClientPermission } from "../../../Users/utils/checkPermission";
import useLoginFormStore from "../../../../../store/useLoginFormStore";
import { IResponse } from "../../../../../_services/interface/user.interface";
import { clearCnpj } from "../util/cnpj";
import { ICertificateParamsForLocation } from "../../../../../_services/interface/certificate.interface";
import { getParamsForLocation } from "../../../../../_services/certificate.service";
import { IClientCustomFields } from "../../../../../_services/interface/client.interface";
import LoadingComponent from "../../../../../components/loading";
import { EditCompanyStateModal } from "./EditCompanyStateModal";
import { getClient } from "../../../../../_services/client.service";
import SelectCustomField from "../../../../../components/SelectCustomField";
import { TaxlyInput } from "../../../../../components/Input";
import CustomAsyncSelect from "../../../../../components/CustomAsyncSelect";

interface ICompanyEditForm {
  name: string;
  cnpj: string;
  stateReg?: string;
  municipalReg?: string;
  cep: string;
  situation?: string|null;
  type: string;
  state: string;
  city: string;
  companyCode: string;
  searchCndAfterUpdate: boolean;
  isScheduled: boolean;
  certSearch?: boolean;
  kanban: boolean;
  cnd: boolean;
  cndt: boolean;
  fgts: boolean;
  sintegra: boolean;
  municipalCnd: boolean;
  stateCnd: boolean;
  federalCnd: boolean;
  field1Guid?: string;
  field2Guid?: string;
  field3Guid?: string;
}

interface IEditCompanyModalProps {
  company: ICompany|undefined;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IValueLabel {
  value: number;
  label: string;
  initials: string;
}

export interface ICompanyStateValue {
  initials: string;
  data: {
    state: string;
    id_state: number;
    cep: string | null;
    stateReg: string | null;
    isActive: boolean;
    isMainState: boolean;
  };
}

interface IClientSelect {
  value: string;
  label: string;
}

//These are the stats where the IE is obligatory
export const statesIe = [9, 10, 13, 12, 14, 17, 26, 27];

export const EditCompanyModal = (props: IEditCompanyModalProps) => {
  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome'),
    cnpj: Yup.string().required('CNPJ'),
    state: Yup.string().required('Estado'),
    city: Yup.string().required('Município'),
    situation: Yup.string().nullable(),
    searchCndAfterUpdate: Yup.bool(),
    isScheduled: Yup.bool(),
    municipalCnd: Yup.bool(),
    stateCnd: Yup.bool(),
    federalCnd: Yup.bool(),
    field1Guid: Yup.string(),
    field2Guid: Yup.string(),
    field3Guid: Yup.string(),
    cep: Yup.string().when([], {
      is: () => cepIsRequired(),
      then: Yup.string().required('CEP'),
    }),
    stateReg: Yup.string().when([], {
      is: () => regIsRequired('state'),
      then: Yup.string().required('Inscrição Estadual'),
    }),
    municipalReg: Yup.string().when([], {
      is: () => regIsRequired('municipal'),
      then: Yup.string().required('Inscrição Municipal'),
    }),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allStates, setAllStates] = useState<IValueLabel[]>([]);
  const [allCities, setAllCities] = useState<IValueLabel[]>([]);
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [genericSimpleErrorMessage, setGenericSimpleErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [currentSelectedState, setSelectedState] = useState<number>();
  const [currentSelectedCity, setSelectedCity] = useState<number>();
  const [isStateChanged, setStateChanged] = useState<number>();
  const [isIeObligatory, setIeObligation] = useState<boolean>(false);
  const [isLoadingData, setLoadingData] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMultiState, setShowMultiState] = useState<boolean>(false)
  const [companyData, setCompanyData] = useState<IGetCompany>();
  const [mainState, setMainState] = useState<ICompanyStateValue>();
  const [multiState, setMultiState] = useState<ICompanyStateValue[]>([])
  const [multiStateLoaded, setMultiStateLoaded] = useState<ICompanyStateValue[]>([])
  const [selectAllStates, setSelectAllStates] = useState<boolean>(false);
  const [field1, setField1] = useState<string>();
  const [field2, setField2] = useState<string>();
  const [field3, setField3] = useState<string>();
  const [customFields, setCustomFields] = useState<IClientCustomFields[]>([]);
  const { validate } = useLoginFormStore();
  const [isParams, setParams] = useState<ICertificateParamsForLocation | null>(null);
  const { formState: { errors }, setValue, register, watch, getValues, handleSubmit } = useForm<ICompanyEditForm>(resolverForm);
  const toast = useToast();

  useEffect(() => {
    if(props.openModalHook && props.openModal && props.company) {
      onOpenModal(props.company);
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal, props.company]);

  const getMandatoryParams = async () => {
    const { status, response } = await getParamsForLocation(props.guid_client, 'number');
    if (status === 200) {
      setParams(response as ICertificateParamsForLocation);
    }
  }

  const cepIsRequired = () => {
    const statesParams = isParams?.mandatory.states[watch().state!];
    if (statesParams?.includes('cep')) {
      return true;
    }

    const citiesParams = isParams?.mandatory.cities[watch().city!];
    if (citiesParams?.includes('cep')) {
      return true;
    }
    return false;
  }

  const regIsRequired = (jurisdiction: 'state'|'municipal') => {
    const statesParams = isParams?.mandatory.states[watch().state!];
    const citiesParams = isParams?.mandatory.cities[watch().city!];

    if (jurisdiction === 'state') {
      return statesParams?.includes('stateReg') || citiesParams?.includes('stateReg');
    } else if (jurisdiction === 'municipal') {
      return statesParams?.includes('municipalReg') || citiesParams?.includes('municipalReg');
    }
    return false;
  }

  const stateList = async (selectedState: string, selectedCity: string) => {
    const { status, response } = await getStates();
    if (status === 200) {
      const organizeStates: IValueLabel[] = [];
      (response as IRegionResponse[]).forEach((_e) => {
        organizeStates.push({
          value: _e.id_state,
          label: _e.name,
          initials: _e.initials
        })
      });

      //Load all the states into a state
      setAllStates(organizeStates);

      const optionValue = organizeStates.find((_x) => _x.label === selectedState);
      if (optionValue) {
        //Mark the selected state
        setSelectedState(optionValue.value);
        //Load all the cities now
      } else {
        setSelectedState(organizeStates[0].value);
        //Set the city by the first state
      }
    }
  };

  const configCustomFields = async (guid_client: string, companyData: IGetCompany) => {
    const { status, response } = await getClient(guid_client);
    if (status === 200) {
      if('clientCustomFields' in response && response.clientCustomFields) {
        setCustomFields(response.clientCustomFields);

        if(response.clientCustomFields[0]) {
          const fieldValue = companyData.customFields.find((cf) => cf.guid_custom_field === response.clientCustomFields[0].guid_custom_field);
          setField1(fieldValue?.value);
          setValue("field1Guid", response.clientCustomFields[0].guid_custom_field);
        }

        if(response.clientCustomFields[1]) {
          const fieldValue = companyData.customFields.find((cf) => cf.guid_custom_field === response.clientCustomFields[1].guid_custom_field);
          setField2(fieldValue?.value);
          setValue("field2Guid", response.clientCustomFields[1].guid_custom_field);
        }

        if(response.clientCustomFields[2]) {
          const fieldValue = companyData.customFields.find((cf) => cf.guid_custom_field === response.clientCustomFields[2].guid_custom_field);
          setField3(fieldValue?.value);
          setValue("field3Guid", response.clientCustomFields[2].guid_custom_field);
        }
      }
    }
  }

  useEffect(() => {
    if (allStates.length > 0 && multiStateLoaded.length > 0) {
      const multiStateInitializer: ICompanyStateValue[] = [];
      allStates.forEach((state) => {
        multiStateInitializer.push({
          initials: state.initials,
          data: {
            cep: null,
            stateReg: null,
            state: state.label,
            id_state: state.value,
            isActive: false,
            isMainState: false
          }
        });
      });

      const updatedMultiState = multiStateInitializer.map((ms) => {
        if (multiStateLoaded.some((csv) => csv.initials === ms.initials)) {
          return multiStateLoaded.find((csv) => csv.initials === ms.initials);
        }
        return ms;
      }) as ICompanyStateValue[];

      setMultiState(updatedMultiState);
    }
  }, [allStates, multiStateLoaded]);

  const cityList = async (id_state: number, selectedCity: string | null = null) => {
    const { status, response } = await getCityByState(id_state);
    if (status === 200) {
      const orgnizeCities: IValueLabel[] = [];
      (response as ICityResponse).cities.forEach((_x) => {
        orgnizeCities.push({
          value: _x.id_city,
          label: _x.name,
          initials: "",
        });
      });

      setAllCities(orgnizeCities);

      if (selectedCity) {
        const optionValue = orgnizeCities.find((_x) => _x.label === selectedCity);
        if (optionValue) {
          //Mark the selected state
          setSelectedCity(optionValue.value);
          //setValue("city", optionValue.value.toString());
        }
      } else {
        setSelectedCity(orgnizeCities[0].value);
        //City not selected so the system will enforce the first value
        //setValue("city", orgnizeCities[0].value.toString());
      }
    } else {
      toast({
        title: 'Falha ao Listar as cidades',
        description: `Algum erro ocorreu ao tentar listar as cidades, por favor, tente novamente mais tarde.`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
      //Close the modal to avoid more errors
      onClose();
    }
    setLoadingData(false);
  }

  const loadStateCity = async (selectedState: string, selectedCity: string) => {
    /* toast({
      title: 'Falha ao Listar as cidades',
      description: `Algum erro ocorreu ao tentar listar as cidades, por favor, tente novamente mais tarde.`,
      status: 'error',
      isClosable: true,
      duration: 5000,
    }); */
    await stateList(selectedState, selectedCity);
  };

  const setEditInfoData = async (companyData: IGetCompany) => {
    setField1(undefined);
    setField2(undefined);
    setField3(undefined);
    setCustomFields([]);
    setValue("field1Guid", '');
    setValue("field2Guid", '');
    setValue("field3Guid", '');

    setCompanyData(companyData);
    configCustomFields(props.guid_client, companyData);
    const mainState = companyData.companyStates.filter((cps) => cps.isMainState === true)[0];
    setMainState({
      initials: mainState.initials,
      data: {
        cep: mainState.cep,
        state: mainState.state,
        id_state: mainState.id_state,
        stateReg: mainState.stateReg,
        isActive: mainState.isActive,
        isMainState: mainState.isMainState,
      }
    });
    setValue("name", companyData.name);
    setValue("cnpj", companyData.cnpj);
    setValue("stateReg", mainState.stateReg ? mainState.stateReg : '');
    setValue("municipalReg", companyData.municipalReg ? companyData.municipalReg : '');
    setValue("cep", mainState.cep ? mainState.cep : '');
    setValue('companyCode', companyData.companyCode);
    setValue('isScheduled', companyData.isScheduled);
    setValue('type', companyData.type.toString());
    setValue('certSearch', companyData.certSearch);
    setValue('situation', companyData.situation);
    const allowedCertsArr = JSON.parse(companyData.allowedCerts);
    setValue("stateCnd", allowedCertsArr.includes("state"));
    setShowMultiState(allowedCertsArr.includes("state"));
    setValue("municipalCnd", allowedCertsArr.includes("municipal"));
    setValue("federalCnd", allowedCertsArr.includes("federal"));

    //Here the system get all the states
    await loadStateCity(mainState.state, companyData.city);

    const companyStateValues = [] as ICompanyStateValue[];
    companyData.companyStates.forEach((cps: CompanyState) => {
      companyStateValues.push({
        initials: cps.initials,
        data: {
          cep: cps.cep,
          stateReg: cps.stateReg,
          state: cps.state,
          id_state: cps.id_state,
          isActive: cps.isActive,
          isMainState: cps.isMainState
        }
      } as ICompanyStateValue);
    });

    setMultiStateLoaded(companyStateValues);
  };

  const fetchCompanyData = async (guid_company: string) => {
    const { status, response } = await getCompany(props.guid_client, guid_company);

    if (status === 200) {
      setEditInfoData(response as IGetCompany);
    } else {
      toast({
        title: 'Falha ao obter dados de empresa',
        description: 'Houve um problema ao obter os dados da empresa para edição.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const updateMainState = (id_state: number) => {
    const oldMainState = multiState.find((ms) => ms.data.isMainState);
    let newMainState = multiState.find((ms) => ms.data.id_state === id_state);
    if (
      multiState.length > 0 &&
      oldMainState &&
      newMainState &&
      oldMainState.initials !== newMainState.initials
    ) {
      const updatedMultiState = multiState.map((ms) => {
        if (ms.initials === oldMainState?.initials) {
          return {
            initials: ms.initials,
            data: {
              ...ms.data,
              isActive: false,
              isMainState: false,
            },
          };
        }
        if (ms.initials === newMainState?.initials) {
          return {
            initials: ms.initials,
            data: {
              ...ms.data,
              cep: oldMainState.data.cep,
              stateReg: oldMainState.data.stateReg,
              isMainState: true,
              isActive: true,
            },
          };
        }
        return ms;
      });
      newMainState = updatedMultiState.find((ms) => ms.data.id_state === id_state);
      setMainState(newMainState);
      setMultiState(updatedMultiState);
    }
  };

  useEffect(() => {
    if (currentSelectedState) {
      setValue("state", currentSelectedState.toString());
      cityList(currentSelectedState, companyData?.city);
      if (mainState && currentSelectedState !== mainState.data.id_state) {
        updateMainState(currentSelectedState);
      }
    }

    if (currentSelectedCity) {
      setValue("city", currentSelectedCity.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedState, currentSelectedCity]);

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  useEffect(() => {
    if (isStateChanged) {
      cityList(isStateChanged, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateChanged]);

  useEffect(() => {
    watch((value) => {
      //Check if the state is different
      if (parseInt(value.state as string) !== currentSelectedState) {
        if (!isLoadingData) {
          setSelectedState(parseInt(value.state as string));
          setStateChanged(parseInt(value.state as string));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, currentSelectedState, currentSelectedCity, isLoadingData]);

  const handleFormInfo = async (data: ICompanyEditForm) => {
    setSubmitDisabled(true);
    setGenericSimpleErrorMessage('');
    let willUpdate = true;

    multiState.forEach((ms) => {
      if (ms.data.isActive && !ms.data.isMainState) {
        const companyStateParams = isParams?.mandatory.states[ms.data.id_state];
        if (companyStateParams?.includes('cep') && ms.data.cep === null) {
          setGenericSimpleErrorMessage('CEP requerido para outros estados.');
          willUpdate = false;
        }

        if (companyStateParams?.includes('stateReg') && ms.data.stateReg === null) {
          setGenericSimpleErrorMessage('Inscrição estadual requerido para outros estados.');
          willUpdate = false;
        }
      }
    });

    //Checks if the CNPJ have more than 14 characters
    if (willUpdate) {
      if (validateCNPJ(data.cnpj) && companyData) {
        let allowedCerts = [];
        if (data.federalCnd) allowedCerts.push("federal");
        if (data.stateCnd) allowedCerts.push("state");
        if (data.municipalCnd) allowedCerts.push("municipal");
        const companyStates =  multiState
          .map((ms) => {
            if (ms.data.isActive || ms.data.isMainState) {
              return {
                id_state: ms.data.id_state,
                cep: !ms.data.isMainState ? ms.data.cep : data.cep ? data.cep : null,
                stateReg: !ms.data.isMainState ? ms.data.stateReg : data.stateReg ? data.stateReg : null,
                isActive: ms.data.isActive,
                isMainState: ms.data.isMainState,
              } as CompanyState;
            }
          })
          .filter((ms) => ms !== undefined);

        const { status, response } = await postEditCompany(props.guid_client, companyData.guid_company, {
          name: data.name,
          cnpj: clearCnpj(data.cnpj),
          companyCode: data.companyCode ? data.companyCode : null,
          type: parseInt(data.type),
          isScheduled: data.isScheduled,
          searchCndAfterUpdate: data.searchCndAfterUpdate,
          stateReg: data.stateReg ? data.stateReg.toString() : null,
          municipalReg: data.municipalReg ? data.municipalReg.toString() : null,
          state: parseInt(data.state),
          city: parseInt(data.city),
          certSearch: data.certSearch,
          situation: data.situation,
          cep: data.cep ? data.cep : null,
          companyStates: companyStates as CompanyState[],
          allowedCerts: allowedCerts,
          field1: Array.isArray(field1) && field1[0] ? field1[0] : null,
          field2: Array.isArray(field2) && field2[0] ? field2[0] : null,
          field3: Array.isArray(field3) && field3[0] ? field3[0] : null,
          field1Guid: data.field1Guid,
          field2Guid: data.field2Guid,
          field3Guid: data.field3Guid,
        });

        if (status === 200) {
          //Refresh the list
          if (response && 'issueCnds' in response && response.issueCnds) {
            toast({
              title: 'Empresa Editada',
              description: 'As alterações foram salvas com sucesso. As novas certidões solicitadas estão sendo pesquisadas e serão disponibilizadas no módulo de certidões.',
              status: 'success',
              duration: 5000,
              isClosable: true
            });
          } else {
            toast({
              title: 'Empresa Editada',
              description: 'As alterações foram salvas com sucesso.',
              status: 'success',
              duration: 5000,
              isClosable: true
            });
          }
        } else {
          toast({
            title: 'Falha ao Editar',
            description: 'Falha ao editar a empresa',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
          //Close the modal
        }

        props.flushHook(true);
        onClose();
      } else {
        setSimpleErrorMessage('CNPJ Inválido');
      }
    }
    setSubmitDisabled(false);
  }

  const updateCompanyStateData = (companyState: ICompanyStateValue) => {
    const companyStateFind = multiState.find((ms) => ms.initials === companyState.initials)
    if (companyStateFind) {
      const updatedMultiState = multiState.map((ms) => {
        if (ms.initials === companyState.initials) {
          return {
            ...companyState
          };
        }
        return ms;
      });

      setMultiState(updatedMultiState);
    }
  }

  const handleMultiState = (initials: string) => {
    const companyState = multiState.find((ms) => ms.initials === initials);
    if (companyState) {
      const updatedMultiState = multiState.map((ms) => {
        if (ms.initials === companyState.initials) {
          const companyStateUpdated = {
            initials: companyState.initials,
            data: {
              cep: companyState.data.cep,
              stateReg: companyState.data.stateReg,
              isMainState: companyState.data.isMainState,
              isActive: !companyState.data.isActive,
              state: companyState.data.state,
              id_state: companyState.data.id_state,
            }
          } as ICompanyStateValue;

          return companyStateUpdated;
        }
        return ms;
      });
      setMultiState(updatedMultiState)
    }
  }

  const handleSelectAllMultiState = () => {
    setSelectAllStates(!selectAllStates);
    if (!selectAllStates) {
      const updatedMultiState = multiState.map(ms => {
        if (!ms.data.isMainState) {
          return {
            initials: ms.initials,
            data: {
              ...ms.data,
              isActive: true,
            }
          };
        }
        return ms;
      });
      setMultiState(updatedMultiState);
    } else {
      const updatedMultiState = multiState.map(ms => {
        if (!ms.data.isMainState) {
          return {
            initials: ms.initials,
            data: {
              ...ms.data,
              isActive: false,
            }
          };
        }
        return ms;
      });
      setMultiState(updatedMultiState)
    }
  }

  const shouldShowEditCompanyState = (initial: string): boolean => {
    const companyState = getCompanyStateToEdit(initial);

    return validateRequiredField(companyState, 'cep') ||
      validateRequiredField(companyState, 'stateReg') ||
      validateEditableField(companyState, 'cep') ||
      validateEditableField(companyState, 'stateReg');
  }

  const onOpenModal = async (company: ICompany) => {
    setLoadingData(true);
    getMandatoryParams();
    await fetchCompanyData(company.guid_company);
    onOpen();
    setLoadingData(false);
  }

  const watchStateCND = watch('stateCnd');

  useEffect(() => {
    setShowMultiState(watchStateCND || getValues('stateCnd'));
  }, [getValues, watchStateCND]);

  const validateRequiredField = (companyState: ICompanyStateValue, field: string) => {
    const statesParams = isParams?.mandatory.states[companyState.data.id_state];

    if (field === 'cep' && statesParams?.includes('cep')) {
      return true;
    } else if (field === 'stateReg' && statesParams?.includes('stateReg')) {
      return true;
    }

    return false;
  }

  const validateEditableField = (companyState: ICompanyStateValue, field: string) => {
    const statesParams = isParams?.editable.states[companyState.data.id_state];

    if (field === 'cep' && statesParams?.includes('cep')) {
      return true;
    } else if (field === 'stateReg' && statesParams?.includes('stateReg')) {
      return true;
    }

    return false;
  }

  const getCompanyStateToEdit = (initial: string): ICompanyStateValue => {
    return multiState.find((ms) => ms.initials === initial) as ICompanyStateValue;
  }

  return (
    <>
      {!props.openModalHook && props.company !== undefined ? (
        <Tooltip label={`Editar ${props.company.name}`}>
          <Flex width="26px" height="26px" resize="none">
            <Image src="../icons/edit.svg" onClick={() => {onOpenModal(props.company!)}} _hover={{ cursor: "pointer" }} />
          </Flex>
        </Tooltip>
        ) : null
      }
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose();
        setMultiState([]);
        setSelectAllStates(false)
        setGenericSimpleErrorMessage('');
      }} size="2xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF">
          <ModalHeader fontFamily="Poppins-Medium">Edição de Empresa</ModalHeader>
          <ModalCloseButton />
          { !isLoadingData ? (
            <ModalBody fontFamily="Poppins-Medium">
              {accErrorMessage.length > 0 ? (
                <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Text>Por favor, informe os campos obrigatórios:</Text>
                  {accErrorMessage.map((_x, _i) => (
                    <Flex key={_i}>{_x}</Flex>
                  ))}
                </Flex>
              ) : null}
              {simpleErrorMessage ? (
                <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Flex>{simpleErrorMessage}</Flex>
                </Flex>
              ) : null}
              <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} pb={4}>
                <Flex mt="10px">
                  <TaxlyInput
                    placeholder="Razão social"
                    label={'Razão social'}
                    isRequired={true}
                    {...register("name", { required: true })}
                  />
                </Flex>

                <Flex  direction="row" gap={2} flexGrow={1}>
                  <Flex width="49%" direction="column">
                    <TaxlyInput
                      placeholder="Digite para preencher"
                      label={'CNPJ'}
                      isRequired={true}
                      mask="99.999.999/9999-99"
                      isDisabled={true}
                      color="#171923"
                      {...register("cnpj", { required: true })}
                    />
                  </Flex>

                  <Flex width="49%" direction="row" gap={2} flexGrow={1}>
                    <Flex width="49%" direction="column">
                      <Flex
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        fontSize="12px"
                      >
                        Tipo
                        <span style={{ color: 'red' }}>*</span>
                      </Flex>

                      <Select
                        variant="outline"
                        borderRadius="4px"
                        border="1px solid #CBD5E0"
                        fontSize="12px"
                        _disabled={{ bg: '#CBD5E0', color: '#CBD5E0'}}
                        {...register("type")}
                      >
                        <option value="0">Matriz</option>
                        <option value="1">Filial</option>
                      </Select>
                    </Flex>

                    <Flex width="49%" direction="column">
                      <Flex
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        fontSize="12px"
                      >
                        Situação
                      </Flex>

                      <Select 
                        variant="outline" 
                        borderRadius="4px" 
                        border="1px solid #E2E8F0" 
                        fontSize="12px" 
                        _disabled={{ bg: '#E2E8F0', color: '#CBD5E0'}}
                        isDisabled={true}
                        {...register("situation")}
                      >
                        <option value="" disabled></option>
                        <option value="ATIVA">Ativa</option>
                        <option value="SUSPENSA">Suspensa</option>
                        <option value="INAPTA">Inapta</option>
                        <option value="BAIXADA">Baixada</option>
                        <option value="NULA">Nula</option>
                      </Select>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction="row" gap={2} flexGrow={1} mt="10px">
                  <Flex width="49%" direction="column">
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      fontSize="12px"
                    >
                      Estado
                      <span style={{ color: 'red' }}>*</span>
                    </Flex>

                    <CustomAsyncSelect
                      placeholder="Selecione o estado"
                      options={allStates}
                      setValue={(value: string | number) => {
                        setValue('state', String(value));
                      }}
                      value={getValues('state')}
                    />
                  </Flex>

                  <Flex width="49%" direction="column">
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      fontSize="12px"
                    >
                      Cidade
                      <span style={{ color: 'red' }}>*</span>
                    </Flex>

                    <CustomAsyncSelect
                      placeholder="Selecione a cidade"
                      options={allCities}
                      setValue={(value: string | number) => {
                        setValue('city', String(value));
                      }}
                      value={getValues('city')}
                    />
                  </Flex>
                </Flex>

                <Flex direction="row" gap={2} flexGrow={1} mt="10px">
                  <Flex width="25%">
                    <TaxlyInput
                      placeholder="CEP"
                      label={'CEP'}
                      mask="99.999-999"
                      {...register("cep")}
                    />
                  </Flex>

                  <Flex width="25%">
                    <TaxlyInput
                      placeholder="Inscrição Estadual"
                      label={'Inscrição Estadual'}
                      {...register("stateReg")}
                    />
                  </Flex>

                  <Flex width="25%">
                    <TaxlyInput
                      placeholder="Inscrição Municipal"
                      label={'Inscrição Municipal'}
                      {...register("municipalReg")}
                    />
                  </Flex>

                  <Flex width="25%">
                    {/* <Tooltip label="Insira aqui o código interno ou do seu sistema de gestão para facilitar localizar o CNPJ pesquisado"> */}
                      <TaxlyInput
                        placeholder="Código da Empresa"
                        label={'Código da Empresa'}
                        {...register("companyCode")}
                      />
                    {/* </Tooltip> */}
                  </Flex>
                </Flex>

                {
                  customFields.length > 0
                  && customFields.some((field) => field.isActive)
                    ? (
                      <Flex mt="10px">
                        <Text
                          color="#171923"
                          fontSize="12px"
                          fontFamily="Poppins-Medium"
                        >
                          Campos personalizados
                        </Text>
                      </Flex>
                    ) : null
                }

                <Flex direction="row" gap={2} flexGrow={1} mt="10px">
                  {
                    customFields[0] && customFields[0].isActive
                      ? (
                        <Flex
                          w={`${100 / customFields.filter((field) => field.isActive).length}%`}
                        >
                          <SelectCustomField
                            setSelectedValue={setField1}
                            selectedValue={field1}
                            customField={customFields[0]}
                            guid_client={props.guid_client}
                            showLabel={true}
                          />
                        </Flex>
                      ) : null
                  }

                  {
                    customFields[1] && customFields[1].isActive
                      ? (
                        <Flex
                          w={`${100 / customFields.filter((field) => field.isActive).length}%`}
                        >
                          <SelectCustomField
                            setSelectedValue={setField2}
                            selectedValue={field2}
                            customField={customFields[1]}
                            guid_client={props.guid_client}
                            showLabel={true}
                          />
                        </Flex>
                      ) : null
                  }

                  {
                    customFields[2] && customFields[2].isActive
                      ? (
                        <Flex
                          w={`${100 / customFields.filter((field) => field.isActive).length}%`}
                        >
                          <SelectCustomField
                            setSelectedValue={setField3}
                            selectedValue={field3}
                            customField={customFields[2]}
                            guid_client={props.guid_client}
                            showLabel={true}
                          />
                        </Flex>
                      ) : null
                  }
                </Flex>

                {checkClientPermission(validate as IResponse, props.guid_client, 'cnd.write') ? (
                  <Flex direction="column" gap={2} flexGrow={1} mt={4}>
                    {!props.company?.isRegFinished ? (
                      <>
                        <Checkbox size='sm' colorScheme='green' {...register("searchCndAfterUpdate")}>
                          <Text fontSize={14}>Pesquisar Certidões ao terminar o cadastro</Text>
                          <Text fontSize="11px" opacity={0.5}>
                            Logo após o cadastro o sistema irá buscar por quaisquer certidões desta empresa.
                          </Text>
                        </Checkbox>
                      </>
                    ) : null}
                    <Checkbox size='sm' disabled={!companyData?.isActive} colorScheme='green' {...register("isScheduled")}>
                      <Text fontSize={14}>Agendar a pesquisa recorrente de certidões para esta empresa?</Text>
                      <Text fontSize="11px" opacity={0.5}>
                        Marcando esta opção, o sistema buscará recorrentemente as certidões cadastradas na plataforma para esta empresa nas datas de recorrências definidas na aba "Configurações" ao lado.
                      </Text>
                    </Checkbox>
                  </Flex>
                ) : null}

                <Flex direction="column">
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex='1' textAlign='left'>
                            <Flex direction="column">
                              <Text fontSize="14px">Selecionar jurisdições para buscar certidões e consultas</Text>
                              <Text fontSize="11px" opacity={0.5}>
                                Por padrão, o sistema irá pesquisar por certidões de origem Federal, Estadual e Municipal (quando disponíveis). Caso deseje que o sistema busque por somente um tipo, por favor selecione abaixo:
                              </Text>
                            </Flex>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex direction="row" gap={4}>
                          <Checkbox size="sm" colorScheme="green" {...register("federalCnd")}>Federal</Checkbox>
                          <Checkbox size="sm" colorScheme="green" {...register("stateCnd")}>Estadual</Checkbox>
                          <Checkbox size="sm" colorScheme="green" {...register("municipalCnd")}>Municipal</Checkbox>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  {genericSimpleErrorMessage ? (
                    <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                      <Flex>{genericSimpleErrorMessage}</Flex>
                    </Flex>
                  ) : null}
                  <Accordion allowToggle index={showMultiState ? undefined : -1 }>
                    <AccordionItem isDisabled={!showMultiState}>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex='1' textAlign='left'>
                            <Flex direction="column">
                              <Text fontSize="14px">Selecionar outros estados para emitir certidões para esta empresa</Text>
                              <Text fontSize="11px" opacity={0.5}>
                              Por padrão, o sistema irá pesquisar as CNDs Estaduais e Dívida Ativa apenas para o Estado de cadastro da empresa. Caso deseje pesquisar CNDs Estaduais e Dívida Ativa para mais de um Estado, por favor selecione os demais Estados que deverão ser pesquisados.
                            </Text>
                            </Flex>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Checkbox mb={4}
                          colorScheme="green"
                          onChange={() => handleSelectAllMultiState()}
                          isChecked={selectAllStates}
                        >
                          <Text fontSize={12}>Selecionar tudo</Text>
                        </Checkbox>
                        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                          {multiState.map((_el, _id) => (
                            <GridItem>
                              <Flex maxW="160px" minW="100px" minH="15px" key={_id}>
                                <Tooltip label={_el.data.id_state === currentSelectedState ? `Empresa já cadastrada em ${_el.data.state}` : null}>
                                  <Flex>
                                    <Checkbox
                                      isChecked={multiState.find((ms) => ms.initials === _el.initials)?.data.isActive}
                                      onChange={() => handleMultiState(_el.initials!)}
                                      colorScheme="green"
                                      isDisabled={_el.data.id_state === currentSelectedState}
                                    >
                                        <Text fontSize={11}>{_el.data.state}</Text>
                                    </Checkbox>
                                    {(_el.data.isActive
                                    && !_el.data.isMainState
                                    && shouldShowEditCompanyState(_el.initials)) ?
                                    (<EditCompanyStateModal
                                        initial={_el.initials}
                                        getCompanyState={getCompanyStateToEdit}
                                        cepRequired={validateRequiredField(getCompanyStateToEdit(_el.initials), 'cep')}
                                        showCep={validateEditableField(getCompanyStateToEdit(_el.initials), 'cep')}
                                        stateRegRequired={validateRequiredField(getCompanyStateToEdit(_el.initials), 'stateReg')}
                                        showStateReg={validateEditableField(getCompanyStateToEdit(_el.initials), 'stateReg')}
                                        updateData={updateCompanyStateData}
                                      />
                                      )
                                    : null}
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            </GridItem>
                          ))}
                        </Grid>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Flex>
                <Flex alignItems="stretch" direction="row" gap={2} flexGrow={1} mt={4} justifyContent="center">
                  {simpleErrorMessage ? (
                    <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                      <Flex>{simpleErrorMessage}</Flex>
                    </Flex>
                  ) : null}
                  <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>Atualizar dados</FormButton>
                </Flex>
              </Flex>

            </ModalBody>
          )
          : (<LoadingComponent />) }
        </ModalContent>
      </Modal>
    </>
  );
}