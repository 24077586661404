import { CalendarIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import useLoginFormStore from "../../../store/useLoginFormStore";
import { getClientCompanies } from "../../../_services/company.service";
import { ICertificateExpiredGraph, ICertificateRenewedGraph, ICNDListData, IFilterListCertificate } from "../../../_services/interface/cnd.interface";
import { IClientCompanies } from "../../../_services/interface/company.interface";
import { IResponse } from "../../../_services/interface/user.interface";
import { ICardList } from "../TaxKanban/interfaces/ICardList";
import {  getKanbanPost } from "../TaxKanban/_services/kanban.service";
import { UserIcon } from "../Users/icons/UserIcon";
import { checkClientPermission } from "../Users/utils/checkPermission";
import { DashCard } from "./widgets/DashCard";
import { CompanyIcon } from "./icons/Company";
import { PinIcon } from "./icons/Pin";
import { CndComplianceWidget } from "./widgets/CNDCompliance";
import { TaxKanbanWidget } from "./widgets/TaxKanban";
import { MapWidget } from "./widgets/Map";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ReloadIcon } from "../TaxKanban/Icons/Reload";
import { useCookies } from "react-cookie";
import { CndPieWidget } from "./widgets/CNDPie";
import { KanbanCompanyList } from "./widgets/CompanyCards";
import moment from 'moment';
import { CertPie } from "./widgets/CertPie";
import { CertBar } from "./widgets/CertBar";
import { ICertificate } from "../../../_services/interface/certificate.interface";
import { getCertificatesPropertyValuesRecursively } from "../Certificate/Util/CertificateUtils";
import { listCertificateExpiredGraph, listCertificateRenewedGraph, listCnds } from "../../../_services/cnd.service";
import { IClientDataResponse } from "../../../_services/interface/client.interface";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../../../components/Filters";
import { CNDExpired } from "./widgets/CNDExpired";
import debounce from 'lodash/debounce';
import { getCancelToken } from "../../../_services/cancelToken";
import { FilesIcon } from "../TaxKanban/Icons/Files";

moment.updateLocale('pt', {
  months: [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});

interface IDashboardPanelProps {
  guid_client: string;
  clientInfo: IClientDataResponse;
  certificates: ICertificate[];
}

interface IWidgetColumn {
  id: string;
  element: JSX.Element | null;
  data: 'cnd' | 'certificateExpiredGraph' | 'certificateRenewedGraph' | 'kanban' | 'companies'
}

const allWidgets: IWidgetColumn[] = [
  {
    id: "fc64d99e-4286-44d3-a343-94e5882690b4",
    element: <CndComplianceWidget />,
    data: 'cnd',
  },
  {
    id: "ba019e20-902e-11ed-a1eb-0242ac120002",
    element: <TaxKanbanWidget />,
    data: 'kanban',
  },
  {
    id: "098e51e1-86ef-9a7q-8e7c-f34db5dae8162",
    element: <CNDExpired />,
    data: 'certificateExpiredGraph',
  },
  {
    id: "eb7b1726-fa0b-49a7-9ec9-e4a1fdfc1b0b",
    element: <CNDExpired />,
    data: 'certificateRenewedGraph',
  },
  {
    id: "c12e4d7e-902e-11ed-a1eb-0242ac120002",
    element: <MapWidget />,
    data: 'companies',
  },
  {
    id: "647e51e1-86ef-4a0a-8e7c-f34db5dae664",
    element: <CndPieWidget />,
    data: 'cnd',
  },
  {
    id: "11d99c98-f594-4e59-aa93-ab69dea294d5",
    element: <CertPie />,
    data: 'cnd',
  },
  {
    id: "c90c5bf5-517b-495b-99b6-fd8702d947a0",
    element: <CertBar />,
    data: 'cnd',
  },
  {
    id: "472df166-a7f1-4498-8bdd-47eec19700a3",
    element: <KanbanCompanyList />,
    data: 'kanban',
  }
];

export const DashboardPanel = (props: IDashboardPanelProps) => {
  const [isLoading, setLoadingState] = useState<boolean>(true);

  const toast = useToast();
  const { validate } = useLoginFormStore();

  //Data to be used on the dashboard components
  const [currentClient, setCurrentClient] = useState<IClientDataResponse>();
  const [clientCompanies, setClientCompanies] = useState<IClientCompanies[]>();
  const [kanbanData, setKanbanData] = useState<ICardList[]>([]);
  const [filteredCNDData, setFilteredCNDData] = useState<ICNDListData[]>([]);
  const [certificateExpiredGraphData, setCertificateExpiredGraphData] = useState<ICertificateExpiredGraph>();
  const [certificateRenewedGraphData, setCertificateRenewedGraphData] = useState<ICertificateRenewedGraph>();
  // const [timeSaving, setTimeSaving] = useState<string>("0");
  const [refreshData, setRefreshData] = useState<boolean>(false);
  
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const [startCreatedAtFilter, setStartCreatedAtFilter] = useState<string|null>(null);
  const [endCreatedAtFilter, setEndCreatedAtFilter] = useState<string|null>(null);

  //Columns
  const [listColumn1, setColumn1] = useState<IWidgetColumn[]>([]);
  const [listColumn2, setColumn2] = useState<IWidgetColumn[]>([]);
  const [cookies, setCookie] = useCookies(["dashboard"]);

  //#region [ RESULT GATHERING ]


  const debouncedRefreshData = useCallback(debounce(() => setRefreshData(true), 600), []);
  useEffect(() => {
    debouncedRefreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDataSelected]);

  useEffect(() => {
    if(refreshData) {
      GetGeneralData();
      updateDateFilters(filterDataSelected);
      setRefreshData(false);
    }
  }, [refreshData]);

  useEffect(() => {
    configCompanyOptions();
  }, [props.guid_client]);

  const configCompanyOptions = async () => {
    if (props.guid_client) {
      const { status, response } = await getClientCompanies(props.guid_client);
      if (status === 200 && response instanceof Array) {
        const allCompanies = response as IClientCompanies[];
        setClientCompanies(allCompanies);
      }
    }
  }

  const GetKanbanCards = async (client: string) => {
    const { status, response } = await getKanbanPost(client);
    if (status === 200 && response && 'allCards' in response) {
      setKanbanData(response.allCards as ICardList[]);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Não foi possível retornar as informações do Kanban, tente novamente mais tarde ou recarregue a página.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const updateDateFilters = (filterDataSelected: FilterDataSelected | null) => {
    if (filterDataSelected) {
      const startCreatedAt = filterDataSelected[FilterTypeEnum.startCreatedAtFilter];
      const endCreatedAt = filterDataSelected[FilterTypeEnum.endCreatedAtFilter];

      if (startCreatedAt && startCreatedAt.length > 0) {
        setStartCreatedAtFilter(startCreatedAt[0] === '' ? null : startCreatedAt[0]);
      }

      if (endCreatedAt && endCreatedAt.length > 0) {
        setEndCreatedAtFilter(endCreatedAt[0] === '' ? null : endCreatedAt[0]);
      }
    }
  }

  const getFilter = (): IFilterListCertificate => {

    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const companies: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [];
    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const companyTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [];
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    const statuses: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStatusFilter] : [];

    const jurisdictions: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const states: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStateFilter] : [];
    const cities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateCityFilter] : [];
    const searchTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.searchTypeFilter] : [];

    const startCreatedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.startCreatedAtFilter][0] : undefined;
    const endCreatedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.endCreatedAtFilter][0] : undefined;
    const startIssuedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.startIssuedAtFilter][0] : undefined;
    const endIssuedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.endIssuedAtFilter][0] : undefined;

    const filters: IFilterListCertificate = {
      page: 1,
      row_by_page: -1,
      ...(validFilterField(startCreatedAtDate) ? { initial_created_at_date: startCreatedAtDate } : {}),
      ...(validFilterField(endCreatedAtDate) ? { final_created_at_date: endCreatedAtDate } : {}),
      ...(validFilterField(startIssuedAtDate) ? { initial_issue_date: startIssuedAtDate } : {}),
      ...(validFilterField(endIssuedAtDate) ? { final_issue_date: endIssuedAtDate } : {}),
      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(companyTypes) ? { company_types: companyTypes.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(states) ? { id_states: states.join(',') } : {}),
      ...(validFilterField(cities) ? { id_cities: cities.join(',') } : {}),
      ...(validFilterField(searchTypes)   ? { is_manual: searchTypes.join(',') } : {}),
    };

    if(validFilterField(statuses)) {
      const statusValues: string[] = []; 
      statuses.forEach((status) => {
        const statusSplited = status.split('_');
        const stat = statusSplited[statusSplited.length - 1];

        if (!statusValues.some((s) => s === stat)) {
          statusValues.push(stat);
        }
      });

      const certificateValues: string[] = [];
      statuses.forEach((status) => {
        const statusName = status.split('_')[0];

        let certs = props.certificates.map((c) => c.slug);

        certs.forEach((cert) => {
          const c = props.certificates.find((c) => c.slug === cert);

          if (c?.status?.some((s) => s.name === statusName) && !certificateValues.some((c) => c === cert)) {
            certificateValues.push(cert);
          }
        });
      });

      filters.status = statusValues.join(',');
      if (validFilterField(certificateValues)) {
        filters.slugs = certificateValues.join(',');
      }
    }

    return filters;
  }

  const getListCndData = async () => {
    //Get all the CND cards for everyone
    if (checkClientPermission((validate as IResponse), props.guid_client, 'cnd.read')) {
      const filters = getFilter();
      const { status, response } = await listCnds(props.guid_client, filters);

      if (status === 200 && 'meta' in response) {

        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          setFilteredCNDData(response.data);
        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das CNDs ou recarregue a página',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      setFilteredCNDData([]);
    }

  }

  const getCertificateExpiredGraph = async () => {
    if (checkClientPermission((validate as IResponse), props.guid_client, 'cnd.read')) {
      const filters = getFilter();

      const { status, response } = await listCertificateExpiredGraph(props.guid_client, filters, getCancelToken('certificate.dataExpiredGraph'));

      if (status === 200) { 
        setCertificateExpiredGraphData(response as ICertificateExpiredGraph);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações do gráfico',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      setCertificateExpiredGraphData(undefined);
    }

  }

  const getCertificateRenewedGraph = async () => {
    if (checkClientPermission((validate as IResponse), props.guid_client, 'cnd.read')) {
      const filters = getFilter();

      const { status, response } = await listCertificateRenewedGraph(props.guid_client, filters, getCancelToken('certificate.dataRenewedGraph'));

      if (status === 200) {
        setCertificateRenewedGraphData(response as ICertificateRenewedGraph);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações do gráfico',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      setCertificateRenewedGraphData(undefined);
    }

  }

  const GetGeneralData = async () => {
    setLoadingState(true);
    
    if (checkClientPermission((validate as IResponse), props.guid_client, 'kanban.read')) {
      await GetKanbanCards(props.guid_client);
    } else {
      setKanbanData([]);
    }

    await getListCndData();
    getCertificateExpiredGraph();
    getCertificateRenewedGraph();
    setLoadingState(false);
  }

  //#endregion

  useEffect(() => {
    setCurrentClient(props.clientInfo);
  }, [props.clientInfo]);

  useEffect(() => {
    if (cookies.dashboard) {
      setColumn1(allWidgets.filter((x) => cookies.dashboard.find((y: any) => y.column === 1).data.includes(x.id)))
      setColumn2(allWidgets.filter((x) => cookies.dashboard.find((y: any) => y.column === 2).data.includes(x.id)))
    } else {
      defaultList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCookie("dashboard", [
      {
        column: 1,
        data: listColumn1.map((x) => x.id),
      },
      {
        column: 2,
        data: listColumn2.map((x) => x.id),
      }
    ], { path: "/", sameSite: "lax" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listColumn1, listColumn2]);

  const stateCount = (): string[] => {
    if (clientCompanies) {
      const states: string[] = [];
      clientCompanies.forEach((x) => {
        states.push(x.state);
      });
      return states.filter((elem, index, self) => index === self.indexOf(elem));
    } else {
      return [];
    }
  }

  const kanbanCount = (): ICardList[] => {
    if (kanbanData) {
      const dataAtual = new Date();
      const ano = dataAtual.getFullYear().toString();
      const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
      
      let startDateFilterObject = {
        mes: parseInt(mes),
        ano: parseInt(ano)
      };

      let endDateFilterObject = {
        mes: parseInt(mes),
        ano: parseInt(ano)
      };

      const regexDataAmericana = /^\d{4}-\d{2}-\d{2}$/;
      if (startCreatedAtFilter) {
        if (regexDataAmericana.test(startCreatedAtFilter)) {
          const [ano, mes] = startCreatedAtFilter.split('-');
          startDateFilterObject = {
            mes: parseInt(mes),
            ano: parseInt(ano)
          };
        }
      }

      if (endCreatedAtFilter) {      
        if (regexDataAmericana.test(endCreatedAtFilter)) {
          const [ano, mes] = endCreatedAtFilter.split('-');
          endDateFilterObject = {
            mes: parseInt(mes),
            ano: parseInt(ano)
          };
        }
      }

      return kanbanData
      .filter((elem, index, self) => index === self.indexOf(elem))
      .filter((_c) => {
        const itemDate = new Date(_c.dueDate);
        const startDate = new Date(startDateFilterObject.ano, startDateFilterObject.mes - 1);
        const endDate = new Date(endDateFilterObject.ano, endDateFilterObject.mes);
      
        return itemDate >= startDate && itemDate <= endDate;
      });
    } else {
      return [];
    }
  }

  const cndCount = (): ICNDListData[] => {
    if (filteredCNDData) {
      let originalClone = [...filteredCNDData];
      const products = currentClient?.products ? currentClient.products : [];

        const slugsAvailable: string[] = [];
        props.certificates?.forEach((certificate) => {
          if (products[certificate.slug as string]) {
            const slugs = getCertificatesPropertyValuesRecursively([certificate], 'slug' as keyof ICertificate);
            slugs[0]?.forEach((slug: string) => {
              slugsAvailable.push(slug);
            });
          }
        }); 
      originalClone = originalClone.filter(item => item.slug_status === 'not-pendency' && slugsAvailable.includes(item.slug));
      return originalClone
    } else {
      return [];
    }
  }

  //The default Widget List organization
  const defaultList = () => {
    const column1: IWidgetColumn[] = allWidgets.filter((x) => ["fc64d99e-4286-44d3-a343-94e5882690b4", "ba019e20-902e-11ed-a1eb-0242ac120002", "472df166-a7f1-4498-8bdd-47eec19700a3", "c90c5bf5-517b-495b-99b6-fd8702d947a0", "11d99c98-f594-4e59-aa93-ab69dea294d5"].includes(x.id));
    const column2: IWidgetColumn[] = allWidgets.filter((x) => ["647e51e1-86ef-4a0a-8e7c-f34db5dae664", "098e51e1-86ef-9a7q-8e7c-f34db5dae8162", "eb7b1726-fa0b-49a7-9ec9-e4a1fdfc1b0b", "c12e4d7e-902e-11ed-a1eb-0242ac120002"].includes(x.id));;

    setColumn1(column1);
    setColumn2(column2);
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const itemsDestination = result.destination.droppableId === 'column1' ? Array.from(listColumn1) : Array.from(listColumn2);
    const itemsSource = result.source.droppableId === 'column1' ? Array.from(listColumn1) : Array.from(listColumn2);

    //Remove the element from the Origin
    const cloneElement = { ...itemsSource[result.source.index] };
    itemsSource.splice(result.source.index, 1);
    //delete itemsSource[result.source.index];
    //Update the source element
    if (result.source.droppableId === 'column1') setColumn1(itemsSource); else setColumn2(itemsSource);
    //Checks if the destination already have this item
    if (itemsDestination.find((x) => x.id === result.draggableId)) {
      //Yep the same element exists here, so the system needs to also remove
      itemsDestination.splice(result.source.index, 1);
    }

    //Now adds the new element to the destination
    itemsDestination.splice(result.destination.index, 0, cloneElement);
    //itemsDestination.push(cloneElement);
    //Now updates the destination element
    if (result.destination.droppableId === 'column1') setColumn1(itemsDestination); else setColumn2(itemsDestination);
  }

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.startCreatedAtFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.endCreatedAtFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.startIssuedAtFilter,
          position: 3,
          size: 3,
        },
        {
          name: FilterTypeEnum.endIssuedAtFilter,
          position: 4,
          size: 3,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyTypeFilter,
          position: 3,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 4,
          size: 2,
        },
      ],
      position: 2
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.jurisdictionFilter,
          position: 1,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateStatusFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.certificateStateFilter,
          position: 3,
          size: 3,
        },
        {
          name: FilterTypeEnum.certificateCityFilter,
          position: 4,
          size: 4,
        }
      ],
      position: 3
    }
  ] as FilterRow[];

  return (
      <Flex direction="column" p="0" w="100%" flexGrow="1" flexShrink="1" pr="30px" mt="10px" height="100%" >
        <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
          <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">{`Dashboard`} </Text>
          <Flex gap={2}>
            <ButtonGroup size="sm" mt="24px" isAttached color="white" >
              <Button leftIcon={<ReloadIcon />} onClick={() => {defaultList();GetGeneralData();}} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
                Atualizar dashboard
              </Button>
            </ButtonGroup>
          </Flex>
        </Flex>

        { !!props.guid_client ?
          (<Filters
            guid_client={props.guid_client}
            filters={filterRows}
            selectedValuesHook={setFilterDataSelected}
            certificates={props.certificates}
            currentMonthFilled={true}
          />) : null
        }

        <Flex flexDir="row" justifyContent="center" gap={4} mt={4}>
          {/* <DashCard icon={<TimeIcon />} color="#4fda95" isLoading={isLoading} title={`Tempo economizado`} value={timeSaving} /> */}
          <DashCard icon={<CompanyIcon />} color="#3fc8ef" isLoading={isLoading} title="Quantidade de empresas" value={clientCompanies ? clientCompanies.length : 0} />
          <DashCard icon={<PinIcon />} color="#7e7ced" isLoading={isLoading} title="Estados" value={stateCount().length} />
          <DashCard icon={<CalendarIcon />} color="#266aff" isLoading={isLoading} title={`Obrigações acessórias`} value={kanbanCount().length} />
          <DashCard icon={<FilesIcon />} color="#4b4eff" isLoading={isLoading} title={`Certidões renovadas`} value={certificateRenewedGraphData ? certificateRenewedGraphData?.renovadasAuto + certificateRenewedGraphData?.renovadasManual : 0} />
        </Flex>
        <Flex mt={4} gap={4} pb="100px">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="column1">
              {(provided) => (
                <Flex flexDirection="column" width="50%" gap={4} {...provided.droppableProps} ref={provided.innerRef}>
                  {listColumn1.map((_el, _i) => {
                    if (_el) {
                      return React.cloneElement(_el.element as JSX.Element, { 
                        id: _el.id,
                        key: _el.id,
                        index: _i,
                        selectedCompanyValues: filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [],
                        selectedTypeCompanyValues: filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [],
                        isLoading,
                        currentClient,
                        startCreatedAtFilter,
                        certificates: props.certificates,
                        endCreatedAtFilter,
                        data: (_el.data === 'cnd' ? filteredCNDData : _el.data === 'certificateExpiredGraph' ? certificateExpiredGraphData : _el.data === 'certificateRenewedGraph' ? certificateRenewedGraphData : _el.data === 'kanban' ? kanbanData : clientCompanies),
                        filter: getFilter(),
                        typeCertificateGraph: _el.data === 'certificateExpiredGraph' ? 'expired' : _el.data === 'certificateRenewedGraph' ? 'renewed' : null
                      })
                    } else {
                      return null;
                    }
                  })}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
            <Droppable droppableId="column2">
              {(provided) => (
                <Flex flexDirection="column" width="50%" gap={4} {...provided.droppableProps} ref={provided.innerRef}>
                  {listColumn2.map((_el, _i) => {
                    if (_el) {
                      return React.cloneElement(_el.element as JSX.Element, { 
                        id: _el.id, 
                        key: _el.id, 
                        index: _i, 
                        selectedCompanyValues: filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [], 
                        selectedTypeCompanyValues: filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [], 
                        isLoading, 
                        currentClient, 
                        startCreatedAtFilter, 
                        certificates: props.certificates, 
                        endCreatedAtFilter, 
                        data: (_el.data === 'cnd' ? filteredCNDData : _el.data === 'certificateExpiredGraph' ? certificateExpiredGraphData : _el.data === 'certificateRenewedGraph' ? certificateRenewedGraphData : _el.data === 'kanban' ? kanbanData : clientCompanies),
                        filter: getFilter(),
                        typeCertificateGraph: _el.data === 'certificateExpiredGraph' ? 'expired' : _el.data === 'certificateRenewedGraph' ? 'renewed' : null
                      });
                    } else {
                      return null;
                    }
                  })}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </Flex>
  )
}